import styled from 'styled-components'

interface Props {
    isError: string;
}

export const ContainerInput = styled.div`
    padding: 0.5rem;
    background: #ffffff;
    border: 5px solid black;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.4rem;
    width: 20rem;
    
    button {
        height: 2rem;
        width: 2rem;
        
        img {
            height: 3rem;
            width: 3rem;
        }
    }
`

export const InputField = styled.input<Props>`
  width: 100%;
  overflow-y: hidden;
  padding-left: 0.57rem;
  border: none;
  outline: none;

    &::placeholder {
        color: ${props => props.isError ? 'red' : 'var(scrollbar-color)'};
    }
`
