import * as analytics from '../lib/analytics'

export type ProfileInfo = {
  name?: string
  email?: string
  createdTime?: Date
}

export function updateInfo({ email, name, createdTime }: ProfileInfo) {
  if (email && email !== '') {
    analytics.registerOnce({ '$email': email })
  }

  if (name && name !== '') {
    analytics.registerOnce({
      '$name': name,
      '$avatar': `https://ui-avatars.com/api/?name=${name}&background=aaaaff&rounded=true`,
    })
  }

  if (createdTime) {
    analytics.registerOnce({ '$created': createdTime })
  }
}
