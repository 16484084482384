import React, { Suspense, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { ROUTE } from '../../router/Router.tsx'
import { MemoizedSpinner } from '../spinner/Spinner.tsx'
import { useChat } from '@/store/useChat.ts'
import { Header, HeaderMemo } from '@/src-ideation/components/Header'
import { InfoPoPUp } from '@/components/infoPoPUp/InfoPoPUp.tsx'
import { useOnlineStatus } from "@/src-ideation/hooks/useOnlineStatus.tsx";
import { toast } from "react-toastify";
import * as analytics from '@/lib/analytics.ts'

function checkIfQueryParamExists(allSearchParams: string, queryParam: string): boolean {
  return allSearchParams.indexOf(queryParam) !== -1
}

interface LayoutProps {
  isSessionLoading: boolean
  isAuthenticated: boolean
  isUserLoading: boolean
  showPopUp: boolean
  onHandleClose: () => void
}

export const Layout: React.FC<LayoutProps> = React.memo(({ isSessionLoading, isAuthenticated, isUserLoading, showPopUp, onHandleClose }) => {
  const location = useLocation()
  const { isKycFetching } = useChat(store => store)
  const isLoading =
    location.pathname === ROUTE.SIGN_IN && checkIfQueryParamExists(location.search, 'descope-login-flow')

  const { isOnline, isFirstRender } = useOnlineStatus()

  useEffect(() => {
    if ( !isOnline ) {
      toast.error('Ooops, looks like Leo can\'t access the internet, check your internet connection or try again later', { autoClose: 3000 })
      analytics.track('Internet connection lost', {})
    }
    if ( isOnline && !isFirstRender ) {
      toast.info('Leo is back online!', { autoClose: 3000 })
    }
  }, [ isOnline ]);

  return (
    <div style={{ position: 'relative' }}>
      <Suspense fallback={<>
        <HeaderMemo onNewSessionClicked={()=>{}} />
      </>}>
        {isAuthenticated && location.pathname !== ROUTE.CHAT && <Header {...{ onNewSessionClicked: () => {} }} />}

        {showPopUp && <InfoPoPUp onHandleClose={onHandleClose} />}

        {isSessionLoading || isUserLoading || isLoading || isKycFetching ? <Loading/> : (
          ''
        )}
        <main>
          <Outlet />
        </main>
      </Suspense>
    </div>
  )
})

const Loading = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: '#231c2b',
        zIndex: 1111,
        height: '100vh',
      }}
    >
      <div
        className="container-center"
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <MemoizedSpinner />
      </div>
    </div>
  )
}
