import { create } from 'zustand'

export type State = {
  credits?: number
  setCredits: (credits: number) => void
  isFinite?: boolean
  setIsFinite: (isFinite: boolean) => void
  isUserHasCreditsObject?: boolean
  setIsUserHasCreditsObject: (isUserHasCreditsObject: boolean) => void
  userImage: string
  setUserImage: (userImage: string) => void
}
export const useAccountInfoStore = create<State>(set => ({
  credits: undefined,
  setCredits: credits => set({ credits }),

  isFinite: false,
  setIsFinite: isFinite => set({ isFinite }),

  isUserHasCreditsObject: false,
  setIsUserHasCreditsObject: isUserHasCreditsObject => set({ isUserHasCreditsObject }),

  userImage: '',
  setUserImage: userImage => set({ userImage }),
}))
