import React, { useState, useContext } from 'react'
import { darkTheme, lightTheme } from '../styles/theme'

const ThemeContext = React.createContext({})

const LeoThemeProvider = ({ children, theme }) => {
  const [currentTheme, setCurrentTheme] = useState(theme || darkTheme)

  const toggleTheme = () => {
    setCurrentTheme(currentTheme.palette.mode === 'light' ? darkTheme : lightTheme)
  }

  return (
    <ThemeContext.Provider
      value={{
        theme: currentTheme,
        toggleTheme,
        mode: currentTheme.palette.mode,
        isDark: currentTheme.palette.mode === 'dark',
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

const useThemeContext = () => {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error('useThemeContext must be used within a ThemeProvider')
  }
  return context
}

export { LeoThemeProvider, useThemeContext }
