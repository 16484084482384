export function isRunningInElectron() {
  if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
    return true
  }
  if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
    return true
  }
  return typeof navigator === 'object' && navigator.userAgent.indexOf('Electron') >= 0
}

export const isMobileDevice = () => {
  const userAgent = navigator.userAgent

  if (/android/i.test(userAgent)) {
    return true;
  }

  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return true;
  }

  return false;
};
