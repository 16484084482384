import Logo from '../../assets/icons/logo-light.svg?react'
import styles from './styles.module.scss'
import { useLocation } from 'react-router-dom'
import * as urls from '../../lib/urls'
import { ToastContainer } from 'react-toastify'

export const AuthLayout = ({ children }) => {
  const location = useLocation()

  const isSignIn = location.pathname === '/sign-in'
  const isSignUp = location.pathname === '/sign-up'

  return (
    <div className={`${styles.AuthWrapper} ${isSignIn && styles.signIn}`}>
      <div className={styles.leftSection}>
        <div className={styles.logoWrapper}>
          <Logo />
        </div>
        <div className={styles.titleWrapper}>
          <h1>
            {isSignIn ? (
                <>
                  Streamline your engineering process.<br/><br/>

                  Log in to Leo AI.
                </>
            ) : (
              <>
                Create Your Leo Account<span className={styles.blue}>.</span>
              </>
            )}
          </h1>

          {isSignUp && (
            <p>
              Generate innovative ideas, get reliable answers to complex questions and find parts from any catalogue effortlessly.
            </p>
          )}
        </div>
        <div className={styles.footer}>
          <a className={styles.mail} href={`mailto:${urls.supportEmail()}`} target="_blank" rel="noreferrer">
            {urls.supportEmail()}
          </a>
        </div>
      </div>
      <div className={styles.rightSection}>
        <div className={styles.formWrapper}>{children}</div>
      </div>
      <ToastContainer theme="colored" autoClose={4000} />
    </div>
  )
}
