import axios, { AxiosError, AxiosInstance, HttpStatusCode, RawAxiosRequestHeaders } from 'axios'
import axiosRetry from 'axios-retry'
import { getSessionToken } from '@descope/react-sdk'

import * as observability from '../lib/observability'

function isAuthorizationTokenExpired(error: AxiosError): boolean {
  return (
    error.response?.status === HttpStatusCode.Unauthorized &&
    (error.response.data as any)?.reason === 'Authorization Token Expired'
  )
}

function applyAuthorizationHeader(headers: RawAxiosRequestHeaders) {
  const sessionToken = getSessionToken()
  headers['Authorization'] = `Bearer ${sessionToken}`
}

function applyRetry(instance: AxiosInstance) {
  axiosRetry(instance, {
    retries: 3,
    onRetry: (retryCount, error) => {
      observability.breadcrumb('warning', 'api', 'Axios retry', {
        retryCount,
        errorCause: error.cause,
        errorMessage: error.message,
      })

      console.warn(`Axios retry #${retryCount}, ${error.cause} - ${error.message}`)
    },
    retryDelay(retryCount) {
      return retryCount * 1000
    },
    retryCondition(error) {
      const serviceUnavailable = error.response?.status === HttpStatusCode.ServiceUnavailable
      return isAuthorizationTokenExpired(error) || serviceUnavailable
    },
  })
}

export default function createInstance() {
  const instance = axios.create()
  applyAuthorizationHeader(instance.defaults.headers.common)
  applyRetry(instance)
  return instance
}
