import { create } from 'zustand'

export type State = {
  sessions: any[]
  setSessions: (sessions: any[]) => void

  sessionNum?: number
  setSessionNum: (sessionNum: number | undefined) => void

  revisionNum?: number
  setRevisionNum: (revisionNum: number | undefined) => void

  isGeneratedAlready?: boolean
  setIsGeneratedAlready: (isGeneratedAlready: boolean) => void
}

export const useSessionStore = create<State>(set => ({
  sessions: [],
  setSessions: (sessions: any[]) => set({ sessions }),

  sessionNum: undefined,
  setSessionNum: (sessionNum: number | undefined) => set({ sessionNum }),

  revisionNum: undefined,
  setRevisionNum: (revisionNum: number | undefined) => set({ revisionNum }),

  isGeneratedAlready: false,
  setIsGeneratedAlready: (isGeneratedAlready: boolean) => set({ isGeneratedAlready }),
}))
