import {create} from "zustand";

export interface OnlineState {
  isOnline: boolean;
  setIsOnline: (isOnline: boolean) => void;
  isFirstRender: boolean;
  setIsFirstRender: (firstRender: boolean) => void;
}

export const useOnlineStore = create<OnlineState>((set) => ({
  isOnline: navigator.onLine,
  setIsOnline: (isOnline) => set({ isOnline }),
  isFirstRender: true,
  setIsFirstRender: (isFirstRender) => set({ isFirstRender }),
}));
