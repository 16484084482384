import { SENTRY_DSN, SENTRY_ENVIRONMENT } from "./config";

import * as sentry from "@sentry/react";
import * as analytics from "./analytics";

const ourUrls = [
  "localhost",
  "https://api.getleo.ai",
  "https://api.prod.leo-primary.com",
];

const ignoreErrors = [
  "Failed to fetch GET https://api.prod.leo-primary.com/api/v1/user/kyc",
  "Failed to fetch PUT https://api.prod.leo-primary.com/api/v3/session",
  "Failed to fetch GET https://api.getleo.ai/api/v1/user/kyc",
  "Failed to fetch PUT https://api.getleo.ai/api/v3/session",
  /^Not found error GET https:\/\/api\.prod\.leo-primary\.com\/api\/v3\/session\/(\d+)\/revision\/(\d+)\/project-document\/(.*)$/,
];

export function init() {
  sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    ignoreErrors,
    attachStacktrace: true,
    integrations: [
      new sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs
        // distributed tracing should be enabled
        tracePropagationTargets: ourUrls,
      }),
      new sentry.Replay({
        networkDetailAllowUrls: ourUrls,
        networkCaptureBodies: true,
        maskAllText: false,
        maskAllInputs: false,
        maxReplayDuration: 1000 * 60 * 5, // 5 minutes
      }),
    ],

    // Important to serialize nested objectts provided in the contexts
    normalizeDepth: 6,

    // Performance monitoring - Couldn't make this fully functional yet
    tracesSampleRate: 0,

    // Replay sampling
    replaysSessionSampleRate: 0.1, // Replay some sessions perhaps we can identify more problems
    replaysOnErrorSampleRate: 1.0, // Replay all errors
  });
}

/**
 * Tags are key value pairs.
 * Keys are searchable strings.
 * Values are primitive values.
 */
export interface ExceptionTags {
  [tag: string]: string | number | undefined;
}

/**
 * Contexts provide additional, non-searchable, potentially complex information about exception events.
 * Use this to provide additional information that can be helpful to debug an exception.
 * For example
 * - The messages of the chat when a policy violation returned
 * - The format of an image the user tried to upload
 */
export interface ExceptionContext {
  [key: string]: any;
}

export type ExceptionInfo = {
  // `tags` are **searchable** key value pairs providing information about the event.
  // They must have primite value types such as string or number.
  // Do not use tags for complex objects that are not meant for search/classification
  // for this purpose, use `context` instead.
  tags?: ExceptionTags;

  // `error` is attached to the event within the "Error" context.
  error?: any;

  // `extraCotext` provide additional information about the event.
  // It is registered as a context named "extra".
  // Context entry value can be objects rather than only primitive value.
  // Use context for sutff like Errors, message arrays and such.
  extra?: ExceptionContext;
};

/**
 * Capture an exception that ocurred in the system.
 *
 * @param {string} message - The message that is attached to the event.
 * chat failed due to policy violation
 *
 * @param {ExceptionInfo} info - Provides additional information about the exception.
 * It contain multiple fields each with it's own semantic. In short:
 * - Use "error" to attach an Error object to the exception.
 * - Use "tags" for primitives that we will want to search for.
 * - Use "extra" for additional information that you think will help the debug.
 *
 * @example
 *
 *     const url = "https://somewhere.com";
 *     const method = "POST";
 *     const data = await getALotOfData();
 *
 *     try {
 *       const response = await fetch(url, {
 *         method: "POST",
 *         body: JSON.stringify(data),
 *       });
 *       console.log({ result: await response.json() });
 *     }
 *     catch (error) {
 *       captureException("Failed to do something", {
 *         error,
 *         tags: { url, method },
 *         extra: { data },
 *       });
 *     }
 */
export function captureException(exception: any, info: ExceptionInfo) {
  sentry.withScope((scope) => {
    scope.setContext("Error", {
      analyticsId: analytics.getDistinctId(),
      error: info.error,
    });

    if (info.extra) {
      scope.setContext("Extra", info.extra);
    }

    if (info.tags) {
      scope.setTags(info.tags);
    }

    if (typeof exception === "string") {
      exception = new Error(exception);
    }

    sentry.captureException(exception);
  });
}

export interface BreadcrumbData {
  [key: string]: any;
}

export type BreadcrumbLevel =
  | "fatal"
  | "error"
  | "warning"
  | "log"
  | "info"
  | "debug";

export function breadcrumb(
  level: BreadcrumbLevel,
  category: string,
  message: string,
  data: BreadcrumbData,
) {
  sentry.withScope((scope) => {
    scope.addBreadcrumb({
      category,
      message,
      level,
      data,
    });
  });
}

export interface SetUserOptions {
  id: string;
  email: string;
  name: string;
}

export function setUser({ id, email, name }: SetUserOptions) {
  sentry.setUser({ id, email, username: name });
}
