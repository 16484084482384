import { useEffect, useCallback, useRef } from 'react'
import { openInputWindow } from '@/lib/app'
import { useCadData } from '@/api/cad-data'
import { useSessionStore } from '@/store/useSessionStore'
import { useChat } from '@/store/useChat'
import * as analytics from '@/lib/analytics'
import { IpcRendererEvent } from 'electron'
import { registerIpcHandlers } from './ipcHandlerManager'

const IntegrationInput = () => {
  const { sessionNum } = useSessionStore(store => store)
  const { setIsLoading, isLoading, messages, setMessages } = useChat(store => store)

  const { postCadData } = useCadData()

  const geometryData = useRef(null)

  const handleReceivedData = useCallback((_event: IpcRendererEvent, data: any) => {
    geometryData.current = data
    openInputWindow()
  }, [])

  const handleInputSubmitted = useCallback(
    async (_event: IpcRendererEvent, text: string) => {
      const content: any = { userMessage: text, geometry: geometryData.current }
      const currentMessages = useChat.getState().messages

      // let dataWithValues = ''
      //todo check if we want to show the values to the user
      Object.keys(geometryData.current || {}).forEach((field: string) => {
        if (
          geometryData.current &&
          typeof geometryData.current[field] !== 'object' &&
          geometryData.current[field] !== null
        ) {
          // dataWithValues += `${field} : ${geometryData.current[field]}, `
        }
      })

      // dataWithValues = dataWithValues.slice(0, -2)

      // const messageWithData = `${text} with ${dataWithValues}`

      setIsLoading(true)
      setMessages([...currentMessages, { sender: 'user', text }])

      if (isLoading) return

      const data = await postCadData(content)
      setIsLoading(false)

      const result = {
        messages: [...currentMessages, ...data.result.messagesList],
        isValid: true,
      }

      analytics.track('Cad Data Received', { isValid: result.isValid })

      if (result.isValid) {
        setMessages([...result.messages])
      }
    },
    [messages, isLoading]
  )

  useEffect(() => {
    registerIpcHandlers(handleReceivedData, handleInputSubmitted)
  }, [handleReceivedData, handleInputSubmitted, sessionNum])

  return null
}

export default IntegrationInput
