
import { Button as MuiButton } from '@mui/material'

export const Button = ({ className, rounded = false, ref= null, ...props }) => {
  return (
    <MuiButton {...props} ref={ref} className={className} style={rounded ? { borderRadius: 'var(--leo-radius)' } : {}}>
      {props.children}
    </MuiButton>
  )
}
