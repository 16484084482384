// Define common errors propagated from the API.
// All errors are of type ApiError.

// Base error for all Api resulted errors
export class ApiError extends Error {}

/**
 * This error is raised when there is no better classification for the error.
 * It should be the last fallback.
 */
export class UnknownApiError extends ApiError {}

/**
 * This error is raised when the request contained a too large resource.
 *
 * For example, the body might contain a too big image.
 */
export class RequestTooLargeApiError extends ApiError {}

export type SchemaValidation = {
  // The path of the field that caused invalidity
  path: string
}

export class SchemaValidationError extends ApiError {
  public constructor(
    public readonly validations: SchemaValidation[] | undefined,
    message: string | undefined
  ) {
    super(message)
  }
}

/**
 * This error is raised when there some policy violation error.
 */
export class PolicyViolationError extends ApiError {}

export class PaymentRequiredError extends ApiError {}
export class NotFoundError extends ApiError {}
