import { IpcRendererEvent } from 'electron'

export type OpenPDFOptions = {
  url: string
}
export type DownloadCadOptions = {
  url: string
}
export type resizeAppWindowOptions = {
  isOpen: boolean
}

export type receivedDataOptions = {
  [key: string]: any
}

export type receivedDataCallback = (event: IpcRendererEvent, data: any) => void

const app = () => (window as any).app

export async function openPDF(opts: OpenPDFOptions) {
  await app().openPDF(opts)
}

export async function resizeAppWindow(opts: resizeAppWindowOptions) {
  await app().resizeAppWindow(opts)
}

export function onReceivedData(callback: receivedDataCallback) {
  app().onReceivedData(callback)
}

export function onInputSubmitted(callback: receivedDataCallback | null) {
  app().onInputSubmitted(callback)
}
export function openInputWindow() {
  app().openInputWindow()
}

export function submitInput(inputText: string | undefined) {
  app().submitInput(inputText)
}

export function hideMinimizeButton(hide: boolean) {
  app().hideMinimizeButton(hide)
}

export function minimizeApp() {
  app().minimizeApp()
}

export function addListener(eventName: string, callback: receivedDataCallback) {
  app().on(eventName, callback)
}

export function onRemoveListener(eventName: string, callback: receivedDataCallback) {
  app().removeListener(eventName, callback)
}

export async function downloadCad(opts: DownloadCadOptions) {
  await app().downloadCad(opts)
}

export async function sendDownloadCadToSW() {
  await app().sendDownloadCadToSW()
}
