import React, { useEffect, useState } from 'react'
import { addListener, minimizeApp } from '@/lib/app.ts'
import { MinimizedButton } from '@/components/minimizeAppButton/MinimizeAppButtonStyle.ts'

const MinimizeAppButton: React.FC = () => {
  const [windowState, setWindowState] = useState(false)

  useEffect(() => {
    addListener('input-submitted', () => {
      setWindowState(false)
    })
  }, [])

  const onClickButton = () => {
    setWindowState(currentState => !currentState)
    minimizeApp()
  }

  return <MinimizedButton onClick={onClickButton}>{windowState ? '>' : '<'}</MinimizedButton>
}

export default MinimizeAppButton
