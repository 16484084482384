import styled from 'styled-components'

export const MinimizedButton = styled.div`
  border-radius: 0 5px 5px 0;
  position: fixed;
  top: 50px;
  left: 10px;
  transform: translateY(-50%) rotate(180deg);
  background-color: rgba(45, 39, 53, 1);
  color: white;
  border-right: 1px solid rgba(80, 74, 84, 1);
  border-left: none;
  border-top: none;
  border-bottom: none;
  padding: 20px 5px;
  cursor: pointer;
  transition: right 0.3s;
  z-index: 1111;

  &:after {
    content: '';
    position: fixed;
    left: -17px;
    top: calc(50% - 45px);
    background-color: rgba(45, 39, 53, 1);
    border-top: 1px solid rgba(80, 74, 84, 1);
    z-index: 111;
    width: 27px;
    height: 43px;
    transform: rotate(45deg);
    border-radius: 0 5px 0 0;
  }
  &:before {
    content: '';
    position: fixed;
    left: -29px;
    top: calc(50% + 9px);
    background-color: rgba(45, 39, 53, 1);
    border-right: 1px solid rgba(80, 74, 84, 1);
    z-index: 111;
    width: 46px;
    height: 25px;
    transform: rotate(45deg);
    border-radius: 31px 5px 0 0;
  }
`
