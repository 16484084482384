import { useFetchData } from '../api'
import { GET_ACCOUNT_CREDITS } from '../lib/config'
import { useAccountInfoStore } from '../store/useAccountInfoStore'
import * as observability from '../lib/observability'

export function useUserCredits() {
  const { fetchData } = useFetchData()
  const { setCredits, setIsFinite, setIsUserHasCreditsObject } = useAccountInfoStore(store => store)

  async function fetchUserCredits(): Promise<void> {
    return await fetchData({
      url: GET_ACCOUNT_CREDITS,
      method: 'GET',
    })
  }
  async function getUserCredits(): Promise<void> {
    try {
      const data: any = await fetchUserCredits()
      setCredits(data.credits.balance)
      setIsFinite(data.credits.finite)
      setIsUserHasCreditsObject(true)
    } catch (error: any) {
      observability.captureException(`User credits error`, { error })
    }
  }

  return {
    getUserCredits,
  }
}
