/**
 * To you: We attempt to hide the fact that the API uses axios.
 * Because of this, we create the types that are needed for our use case.
 * Then, we are free to move from it and change implementation.
 */

import { AxiosInstance } from 'axios'
import createInstance from './createInstance'
import { handleError } from './error-handlers'

export type Method = 'get' | 'GET' | 'post' | 'POST' | 'put' | 'PUT'

export type ResponseType = 'blob' | 'json'

export interface Headers {
  [key: string]: string
}

export interface FetchDataArgs<TData, TParams> {
  data?: TData
  headers?: Headers
  method: Method
  params?: TParams
  url?: string
  responseType?: ResponseType
}

// Keep this private since the signature implies axios usage.
function createFetchDataFunction(api: AxiosInstance) {
  return async function <TData, TParams>(args: FetchDataArgs<TData, TParams>) {
    const { data, headers, method = 'post', params, url, responseType } = args

    try {
      const response = await api({ data, headers, method, params, url, responseType })
      return response.data
    } catch (error: any) {
      handleError(error, args)
    }
  }
}

export default function useFetchData() {
  const api = createInstance()
  const fetchData = createFetchDataFunction(api)
  return { fetchData }
}
