import React from 'react'
import { Button as MuiButton, ButtonProps } from '@mui/material'

interface CustomButtonProps extends ButtonProps {
  className?: string
  rounded?: boolean
}

export const Button: React.FC<CustomButtonProps> = ({ className, rounded = false, children, ...props }) => {
  return (
    <MuiButton {...props} className={className} style={rounded ? { borderRadius: 'var(--leo-radius)' } : {}}>
      {children}
    </MuiButton>
  )
}
