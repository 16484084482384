import React from 'react'
import ReactDOM from 'react-dom/client'

import * as analytics from './lib/analytics'
import * as observability from './lib/observability'
import '@/src-ideation/styles/index.scss'
import InputWindow from '@/components/inputWindow/InputWindow.tsx'
import MinimizeAppButton from '@/components/minimizeAppButton/minimizeAppButton.tsx'
import { App } from './app.tsx'

observability.init();
analytics.init();

const urlParams = new URLSearchParams(window.location.search);
const isInputWindow:any = urlParams.get('inputWindow');
const isButtonWindow:any = urlParams.get('buttonWindow');

if (isInputWindow || isButtonWindow) {
  document.documentElement.style.background = 'transparent';
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    {isInputWindow ? <InputWindow/> : isButtonWindow ? <MinimizeAppButton/>: <App/>}
  </React.StrictMode>,
);
