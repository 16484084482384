import { useUser } from "@descope/react-sdk";
import { useEffect, useState } from "react";

export const useAvatar = () => {
  const { user } = useUser();
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    if (user) {
      const id = encodeURIComponent(user.name || user.email || 'Leo');
      const avatarUrl = `https://ui-avatars.com/api/?name=${id}&background=aaaaff&rounded=true`;
      setImageSrc(avatarUrl);
    }
  }, [user]);

  return {
    imageSrc: (user?.picture) || imageSrc,
  };
};