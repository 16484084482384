import { createTheme } from '@mui/material/styles'

export const darkTheme = createTheme({
  components: {
    MuiSlider: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
        rail: {
          opacity: 1,
        },
        mark: {
          width: '2px',
          height: '14px',
          background: '#A2B6FF',
          bottom: 0,
          top: '10px',
        },
        thumb: {
          color: '#fff',
          zIndex: 1,
          // border: 'solid 7px rgba(116,147,255, 0.38)'
          // '::before': {
          //   zIndex:0,
          //   borderRadius: '50%',
          //   background: '#7493FF',
          //   opacity: 0.38,
          //   width: '28px',
          //   height: '28px'
          // }
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '1.4vh',
          lineHeight: 1,
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        color: 'var(--leo-white)',
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& input:-webkit-autofill': {
            '-webkit-box-shadow': '0 0 0 30px #3b3641 inset !important',
          },
        },
      },
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#3B60E2',
    },
    secondary: {
      main: '#F2EEE8', // -leo-white
    },
    success: {
      main: '#60DEC0',
    },
    warning: {
      main: '#E85454', //--red
    },
  },
  typography: {
    fontFamily: 'DM Sans, sans-serif',
    h1: {
      fontSize: '2rem',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '1.8rem',
      fontWeight: 'bold',
    },
  },
  // Add more theme customizations as needed
})

// TODO need to update
export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#F2EEE8', // -leo-white
    },
    success: {
      main: '#60DEC0',
    },
    warning: {
      main: '#E85454', //--red
    },
  },
})
