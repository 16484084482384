import React, { useEffect, useRef, useState } from 'react'
import { addListener, onRemoveListener, submitInput } from '@/lib/app.ts'
import { ChatButton } from '@/components/chatSection/chatButton/ChatButton.tsx'
import { ContainerInput, InputField } from '@/components/inputWindow/InputWindowStyle.ts'

const InputWindow: React.FC = () => {
  const [input, setInput] = useState('')
  const [error, setError] = useState('')
  const [isVisible, setIsVisible] = useState(false) // New state to track window visibility
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const handleWindowShowOrHide = (show: boolean) => {
      setError('')
      setInput('')
      setIsVisible(show)
    }

    addListener('window-show', () => handleWindowShowOrHide(true))
    addListener('window-hidden', () => handleWindowShowOrHide(false))

    return () => {
      onRemoveListener('window-show', () => handleWindowShowOrHide(true))
      onRemoveListener('window-hidden', () => handleWindowShowOrHide(false))
    }
  }, [])

  useEffect(() => {
    if (isVisible && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isVisible])

  const onSendButton = () => {
    if (input.trim() === '') {
      setError('Description is required')
    } else {
      submitInput(input)
      setError('')
      setInput('')
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      onSendButton()
    }

    if (e.key === 'Escape') {
      submitInput('') //submit with empty string will just close the input window
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value)
    if (e.target.value.trim() !== '') {
      setError('')
    }
  }

  if (!isVisible) return null

  return (
    <ContainerInput>
      <InputField
        ref={inputRef}
        isError={error}
        required
        type="text"
        value={input}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
        placeholder="Type here..."
      />
      <ChatButton onClick={onSendButton} />
    </ContainerInput>
  )
}

export default InputWindow
