import * as errors from "./errors";
import * as analytics from "../lib/analytics";
import * as observability from "../lib/observability";

export type Method = "get" | "GET" | "post" | "POST" | "put" | "PUT";

export interface Headers {
  [key: string]: string;
}

export interface RequestArgs<TParams> {
  headers?: Headers;
  method: Method;
  params?: TParams;
  url?: string;
  data?: any;
}

export function handleNotFoundError<TParams>(
  error: Error,
  args: RequestArgs<TParams>,
) {
  const { headers, method = "get", params, url } = args;
  observability.captureException(`Not found error ${method} ${url}`, {
    error,
    tags: { url, method },
    extra: { params, headers },
  });

  analytics.track("Not Found Error", { error });

  throw new errors.NotFoundError(error.message);
}

export function handleRequestToLargeError<TParams>(
  error: Error,
  args: RequestArgs<TParams>,
) {
  const { headers, method = "post", params, url } = args;
  observability.captureException(`Request is too large ${method} ${url}`, {
    error,
    tags: { url, method },
    extra: { params, headers },
  });

  analytics.track("Request Too Large error", { error });

  throw new errors.RequestTooLargeApiError(error.message);
}

export function handleSchemaValidationError<TParams>(
  error: Error | any,
  args: RequestArgs<TParams>,
) {
  const { headers, method = "post", params, data, url } = args;
  observability.captureException(`Schema validation error ${method} ${url}`, {
    error,
    tags: { url, method },
    extra: { params, headers, data },
  });

  analytics.track("Schema Validation Error", { error });

  // The validations provide information about what validated in the schema.
  // It contains information about what property failed (instancePath) and additional info.
  // For example, we can add information about the available values themselves.
  const validations = error.validation?.map((validation: any) => ({
    path: validation.instancePath,
  }));

  throw new errors.SchemaValidationError(validations, error.message);
}

export function handleUnknownError<TParams>(
  error: Error,
  args: RequestArgs<TParams>,
) {
  const { headers, method = "post", params, url } = args;
  observability.captureException(`Failed to fetch ${method} ${url}`, {
    error,
    tags: { url, method },
    extra: { params, headers },
  });

  analytics.track("Unrecoverable API Error", { error });

  const info = error?.message || `Error with fetching data from ${url}`;

  throw new errors.UnknownApiError(info);
}

//todo when we have credits request,delete handlePaymentRequiredError from here and use it where the credits are.

// function handlePaymentRequiredError<TParams>(
//     error: Error,
//     args: RequestArgs<TParams>,
// ) {
//   const { headers, method = "post", params, url } = args;
//   observability.captureException(`Payment required error ${method} ${url}`, {
//     error,
//     tags: { url, method },
//     extra: { params, headers },
//   });
//
//   analytics.track("Payment Required Error", { error });
//
//   throw new errors.PaymentRequiredError(error.message);
// }
