import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import { Root } from "./Root.js";
import { SignIn, SignUp } from "@/src-ideation/pages/Auth";
import "../styles/index.scss";

export const ROUTE = {
  MAIN: "/",
  IDEATION: "/ideation",
  CHAT: "/",
  SIGN_IN: "/sign-in",
  SIGN_UP: "/sign-up",
  KYC_PAGE: "/kyc-page",
  PROFILE: '/profile',
  GALLERY: '/gallery',
  SESSIONS: '/sessions',
};

const Home = React.lazy(() => import("@/src-ideation/pages/Home.jsx"));
const Chat = React.lazy(() => import("@/components/chatSection/chat/ChatWithPreload.tsx"));
const KycForm = React.lazy(() => import("@/components/kycForm/KycForm.tsx"));
const SessionsPage = React.lazy(() => import("@/src-ideation/components/Sessions/SessionsPage/sessionsPage"));
const Profile = React.lazy(() => import("@/src-ideation/pages/Profile"));
const Gallery = React.lazy(() => import("@/src-ideation/pages/Gallery"));

export const router = createBrowserRouter([
  {
    path: ROUTE.MAIN,
    element: <Root />,
    errorElement: <Navigate to={ROUTE.MAIN} />,
    children: [
      {
        path: ROUTE.IDEATION,
        element: <Home />,
      },
      {
        path: ROUTE.MAIN,
        element: <Chat />,
      },
      {
        path: ROUTE.SIGN_IN,
        element: <SignIn />,
      },
      {
        path: ROUTE.SIGN_UP,
        element: <SignUp />,
      },
      {
        path: ROUTE.KYC_PAGE,
        element: <KycForm />,
      },
      {
        path: ROUTE.PROFILE,
        element: <Profile />,
      },
      {
        path: ROUTE.GALLERY,
        element: <Gallery />,
      },
      {
        path: ROUTE.SESSIONS,
        element: <SessionsPage />,
      },
    ],
  },
]);
