import gifBright from '../../assets/icons/Logo_animation_Bright.gif'
import gifDark from '../../assets/icons/Logo_animation_Dark.gif'
import { SpinnerWrapper, Gif } from './SpinnerStyle.ts'
import React from "react";

export const SPINNER_COLOR = {
  DARK: 'DARK',
  BRIGHT: 'BRIGHT',
}



export const Spinner = (({ size = 200, color = SPINNER_COLOR.DARK }) => (
  <SpinnerWrapper style={{ width: size, height: size }}>
    <Gif src={color === SPINNER_COLOR.BRIGHT ? gifBright : gifDark} alt="Loading..." />
  </SpinnerWrapper>
))

export const MemoizedSpinner = React.memo(Spinner)
