import React, {useCallback, useRef, useState} from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import clsx from 'clsx'
import logoPng from '../../../assets/icons/logo-light.png'
import Logout from '../../../assets/icons/logout.svg?react'
import DownloadIcon from '../../../assets/icons/download.svg?react'
import TollIcon from '../../../assets/icons/toll.svg?react'
import AwardStar from '../../../assets/icons/award_star.svg?react'
import InfoIcon from '../../../assets/icons/info_outline.svg?react'
import LanguageIcon from '../../../assets/icons/language.svg?react'
import SupportIcon from '../../../assets/icons/support.svg?react'
import {useOutsideClick} from '../../hooks/useOutsideClick'
import * as analytics from '../../../lib/analytics'
import {useAccountInfoStore} from '../../store/useAccountInfoStore'
import {ROUTE} from '@/router/Router'
import {Avatar} from '../common/Avatar'
import {Button} from '../common/Button'
import styles from './Header.module.scss'
import {useDescope} from '@descope/react-sdk'
import useScreenSize from '@/components/useScreenSize/useScreenSize.ts'
import { DOWNLOAD_APP_LINK, PRIVACY_POLICY_URL, SUPPORT_URL, WEBSITE_URL } from "@/lib/config";
import { isRunningInElectron } from "@/constants/generalFunctions.ts";

export const Header = ({ onNewSessionClicked }) => {
  const [open, setOpen] = useState(false)
  const wrapperRef = useRef(null)
  const { credits, isFinite, isUserHasCreditsObject } = useAccountInfoStore(store => store)
  useOutsideClick(wrapperRef, () => setOpen(false))
  const location = useLocation()
  const { logout } = useDescope()
  const screenSize = useScreenSize()
  const onLogoutClick = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure?')) {
      analytics.track('User Logged Out')
      analytics.reset()
      handleLogout()
    }
  }
  // const isMobile = isMobileCheck()
  const isMobile = screenSize.width < 800

  const menuToggle = () => setOpen(!open)

  const handleLogout = useCallback(() => {
    logout()
  }, [logout])

  return (
    <header className={styles.Header}>
      <NavLink to={ROUTE.MAIN} className={styles.logoContainer}>
        <img className={styles.logo} src={logoPng} alt="logo"/>
      </NavLink>

      <div className={styles.navWrapper} ref={wrapperRef}>
        <nav className={styles.nav}>
          {location.pathname !== ROUTE.MAIN ? (
            <>
              {!isMobile && (
                <>
                  <NavLink to={ROUTE.GALLERY} className={styles.galleryLink}>
                    <Button color="secondary">Gallery</Button>
                  </NavLink>
                  <NavLink to={ROUTE.SESSIONS} className={styles.galleryLink}>
                    <Button color="secondary">History</Button>
                  </NavLink>
                </>
              )}
            </>
          ) : (
            <span className={styles.galleryLink}>
              <Button
                onClick={() => {
                  analytics.track('History Clicked', {})
                  onNewSessionClicked()
                }}
                color="secondary"
              >
                History
              </Button>
            </span>
          )}

          <NavLink to={ROUTE.IDEATION} className={styles.galleryLink}>
            <Button color="secondary">Ideation</Button>
          </NavLink>

          <NavLink to={ROUTE.MAIN} className={styles.galleryLink}>
            <Button color="secondary">Chat</Button>
          </NavLink>
          <div className={styles.rightMenuButton}>
            <Button className={styles.menuButton} onClick={menuToggle}>
              {isUserHasCreditsObject ? (
                isFinite ? (
                  <div className={styles.accountCredits}>
                    <TollIcon/>
                    <span>{credits}</span>
                  </div>
                ) : (
                  <div className={clsx(styles.accountCredits, styles.prem)}>
                    <AwardStar/>
                    <span>Premium</span>
                  </div>
                )
              ) : null}
              <Avatar/>
            </Button>
            <div className={clsx(styles.menu, open && styles.visible)}>
              {/*<NavLink to={ROUTE.PROFILE}>
                            <Button className={styles.menuItem} color="secondary" onClick={menuToggle}>
                                Account
                            </Button>
                        </NavLink>*/}

              <Button href={WEBSITE_URL}
                      target={'_blank'}
                      className={styles.menuItem}
                      color="secondary"
                      onClick={menuToggle}>
                <LanguageIcon style={{marginRight: 5,marginLeft: -2}}/>
                <span className={styles.menuLink}>Website</span>
              </Button>
              <Button href={PRIVACY_POLICY_URL}
                      target={'_blank'}
                      className={styles.menuItem}
                      color="secondary"
                      onClick={menuToggle}>
                <InfoIcon style={{marginRight: 5,marginLeft: -2}}/>
                <span className={styles.menuLink}>Terms & Privacy</span>
              </Button>
              {!isRunningInElectron() && <Button href={DOWNLOAD_APP_LINK}
                      target={'_blank'}
                      className={styles.menuItem}
                      color="secondary"
                      onClick={menuToggle}>
                <DownloadIcon style={{marginRight: 5}}/>
                <span className={styles.menuLink}>Get desktop app</span>
              </Button>}
              <Button href={`mailto:${SUPPORT_URL}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.menuItem}
                      color="secondary"
                      onClick={menuToggle}>
                <SupportIcon style={{marginRight: 5}}/>
                <span className={styles.menuLink}>Support</span>
              </Button>
              <Button className={styles.menuItem} color="secondary" onClick={onLogoutClick}>
                <Logout/> Logout
              </Button>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

export const HeaderMemo = React.memo(Header)
