import mixpanel from 'mixpanel-browser'
import { MIXPANEL_TOKEN } from './config'
import {isMobileDevice, isRunningInElectron} from "@/constants/generalFunctions.ts";

function registerExternalInfo() {
  const url = new URL(window.location.href)
  const distinctId = url.searchParams.get('distinct_id')
  if (distinctId) {
    const originalDistinctId = mixpanel.get_distinct_id()
    mixpanel.register({ 'distinct_id': distinctId, '$device_id': distinctId })
    track('Registered External Distinct ID', {
      originalDistinctId,
      externalDistinctId: distinctId,
    })
  }
}

// Abstract underlying framework.
// We can add GA as well.

// TODO:
// mixpanel for example, can have automatic tracking of links and form submissions.
// we can support it in the future.

// Initialize the underlying analytics API.
export function init() {
  mixpanel.init(MIXPANEL_TOKEN, {
    // Pageview doesn't play nicely with our client side routing
    track_pageview: false,

    // For persistency, MP suggests to use local storage instead of cookies
    persistence: 'localStorage',

    // Know that this is here, very helpful
    debug: false,
  })

  registerExternalInfo()
}

export interface Dictionary {
  [key: string]: any
}

// Identifies a specific user.
// For example:
//   const userId = 12345678;
//   identify(userId);
export function identify(userId: string) {
  mixpanel.identify(userId)
}

// Clears super properties and generate a new random distinct id.
// Use when a user log out
export function reset() {
  mixpanel.reset()
}

// Register properties for all events.
// For example:
//   register({ userId: 12345678 });
export function register(props: Dictionary) {
  mixpanel.register(props)
}

// Register properties for all events without overriding previously registered properties.
// For example:
//   register({ userId: 12345678 });
export function registerOnce(props: Dictionary) {
  mixpanel.register_once(props)
}

// Track a new event.
// For example:
//   track("GENERATE_CLICKED", { hasPrompt: false });
export function track(event: string, props: Dictionary) {
  mixpanel.track(event, {
    ...props,
    applicationType: isRunningInElectron() ? 'desktop_app' : isMobileDevice() ? 'mobile_web' : 'desktop_web',
  });
}

export function registerUserProperties(props: Dictionary) {
  mixpanel.people.set_once(props)
}

// "someWord" will be "Some Word"
function camelCaseToWords(s: string) {
  const result = s.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export function registerKYCInfo(kyc: any) {
  const kycInfo = Object.entries(kyc).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [camelCaseToWords(key)]: value,
    }),
    {}
  )

  registerUserProperties(kycInfo)
}

export function getDistinctId() {
  return mixpanel.get_distinct_id()
}
